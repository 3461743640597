<template>
  <div class="publicBox">
      <div class="publicCode">
        <img src="@/assets/qrcode/Qrcode.jpg" alt="">
        <div class="title">
            | 操作步骤
        </div>
        <div class="stepsItem">
            <span>1</span>
            <div>
                <p>长按上图保存图片</p>
            </div>
        </div>
        <div  class="stepsItem">   
             <span>2</span>
            <div>
                <p>打开微信扫一扫识别二维码</p>
            </div>
        </div>
        <div  class="stepsItem">
             <span>3</span>
            <div>
                <p>点关注观看本场直播</p>
            </div>
        </div>
     </div>
     <div class="kf">
         <div>如需帮助请咨询Mooby Yoho官方客服</div>
         <img src="@/assets/qrcode/kf.png" alt="">
     </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
    .publicBox {
        width: 100%;
        height: 100%;
        padding: 44px;
        overflow-y: scroll;
        .publicCode {
            position: relative;
            img {
                width: 100%;
                display: block;
        }
      }
      .title {
          margin-top: 25px;
          font-size: 17px;
          font-weight: 600;
          position: relative;
          left: -20px
      }
      .stepsItem {
          margin-top: 10px;
          display: flex;
          align-items: center;
          span {
              width: 18px;
              height: 18px;
              display: block;
              border: solid 1px rgb(255, 123, 0);
              border-radius: 50%;
              color: rgb(255, 123, 0);
              text-align: center;
              font-size: 16px;
              line-height: 18px;
              box-sizing: border-box;
          }
          div {
              margin-left: 10px;
              p {
                  font-size: 14px;
                  color: #333;
              }
          }
      }
      .kf {
          
          margin-top: 50px;
          div {
              color: #666;
              text-align: center;
          }
          img {
              display: block;
              width: 50%;
              margin: auto;
          }
      } 
    }
</style>